<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.ecommerce.paymentMethod.subMethod.titlePattern'), [paymentMethod.name ?? '']) }}
        </h2>

        <router-link to="/ecommerce/payment-method" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.paymentMethod.title") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.paymentMethod.subMethod.title')"
        :subTitle="$t('pages.ecommerce.paymentMethod.subMethod.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchSubMethod(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_sub_method" ref="addSubMethodModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="subMethodForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.name') }}</label>
                                <el-form-item prop="payload.name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.owner') }}</label>
                                <el-form-item prop="payload.owner" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.owner" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.accountNumber') }}</label>
                                <el-form-item prop="payload.account_number" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.account_number" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.branch') }}</label>
                                <el-form-item prop="payload.branch" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.branch" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.branchNumber') }}</label>
                                <el-form-item prop="payload.branch_number" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.branch_number" oninput="value=value.replace(/[^0-9.]/g,'')"  />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.payload.cols.iban') }}</label>
                                <el-form-item prop="payload.iban" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.payload.iban" v-mask="{ mask:'TR99999999999999999999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>


                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.cols.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.paymentMethod.subMethod.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "_id",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.paymentMethod.subMethod.cols.id"),
                    key: "id"
                },
                {
                    name: this.$t("pages.ecommerce.paymentMethod.subMethod.payload.cols.name"),
                    key: "payload.name"
                },
                {
                    name: this.$t("pages.ecommerce.paymentMethod.subMethod.payload.cols.owner"),
                    key: "payload.owner"
                },
                {
                    name: this.$t("pages.ecommerce.paymentMethod.subMethod.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.paymentMethod.subMethod.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {
                    payload: {},
                }
            },
            selectedRowKeys: [],
            table: {
                data: [],
                loading: false
            },
            paymentMethod: {}
        }
    },
    computed: {
        paymentMethodID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.paymentMethodID || !(this.paymentMethodID > 0)) {
            this.$router.push({
                path: "/ecommerce/payment-method"
            });
        }
    },
    mounted() {
        if (this.paymentMethodID && this.paymentMethodID > 0) {
            this.loadPaymentMethod();
        }
    },
    methods: {
        loadPaymentMethod() {
            this.table.loading = true;

            this.axios.get(this.endpoints['ecommerce_payment_method'] + '/' + this.paymentMethodID).then((response) => {
                let data = response.data.data;
                this.paymentMethod = data;
                this.table.data = data.sub_methods;
            }).finally(() => {
                this.table.loading = false;
            });
        },
        newSubMethod() {
            this.form.updateStatus = false;
            this.form.data = {
                method_id: this.paymentMethodID,
                sort: 1,
                payload: {
                    currency_id: this.$root.defaultCurrency.id
                },
                active: true
            };
            this.form.title = this.$t("pages.ecommerce.paymentMethod.subMethod.newSubMethod");
            this.showModal(this.$refs.addSubMethodModal);
        },
        fetchSubMethod(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.paymentMethod.subMethod.editSubMethod");
            this.axios.get(this.endpoints['ecommerce_payment_method_sub_method'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.addSubMethodModal);
            });
        },
        onSubmit() {
            this.$refs.subMethodForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_payment_method_sub_method'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadPaymentMethod();
                                this.hideModal(this.$refs.addSubMethodModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['ecommerce_payment_method_sub_method'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadPaymentMethod();
                                this.hideModal(this.$refs.addSubMethodModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/paymentMethod/subMethod/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if (successDeleted) {
                    this.loadPaymentMethod();
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadPaymentMethod();
                    break;

                case "new":
                    this.newSubMethod();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>